import React, { useState, useMemo, useCallback } from "react";
import { Modal } from "semantic-ui-react";

import moment from "moment";

// UI TPD
import CardPatientSearchBox from "react-lib/apps/HISV3/TPD/CardPatientSearchBox";

// UI ADM
import CardReserveTransferQueueUX from "react-lib/apps/HISV3/ADM/CardReserveTransferQueueUX";
import { MenuItem } from "react-lib/apps/HISV3/ADM/CardADM";
import CardBed from "react-lib/apps/HISV3/ADM/CardBed";
import CardAdmitChange from "react-lib/apps/HISV3/ADM/CardAdmitChange";

// Common
import DateTextBox from "react-lib/apps/common/DateTextBox";
import PatientData from "react-lib/apps/common/PatientData";
import CardPrintList from "react-lib/apps/common/CardPrintList";

// Utils
import { beToAd } from "react-lib/utils/dateUtils";
import { useIntl } from "react-intl";

const CardReserveTransferQueue = (props: any) => {
  const intl = useIntl();
  const [mode, setMode] = useState("");

  const handleGetTrProps = useCallback(
    (state: any, rowInfo: any, column: any, instance: any) => ({
      style: {
        backgroundColor:
          props.SetBedSequence?.selectedReserveBedQueue?.id &&
          rowInfo?.original?.id ===
            props.SetBedSequence?.selectedReserveBedQueue?.id
            ? "#cccccc"
            : "white",
      },
      onClick: (e: any) =>
        handleSelectRow(
          props.SetBedSequence?.reserveTransferQueueList?.[rowInfo?.index]
        ),
    }),
    [
      props.SetBedSequence?.selectedReserveBedQueue,
      props.SetBedSequence?.reserveTransferQueueList,
    ]
  );

  const reserveTransferQueueList = useMemo(() => {
    return (props.SetBedSequence?.reserveTransferQueueList || []).map(
      (item: any) => ({
        ...item,
        division: (
          <div>
            {item.division_name && <div>หน่วย: {item.division_name}</div>}
            {item.ward_type_name && (
              <div>ประเภทหอผู้ป่วย: {item.ward_type_name}</div>
            )}
            {item.room_type_name && (
              <div>ประเภทห้อง: {item.room_type_name}</div>
            )}
          </div>
        ),
      })
    );
  }, [props.SetBedSequence?.reserveTransferQueueList]);

  const patientInfo = useMemo(() => {
    const data = props.SetBedSequence?.selectedReserveBedQueue || {};
    const birthdate = data.patient_birthdate;
    const a = moment();
    const b = moment(beToAd(birthdate));
    const diff = a.diff(b, "years");

    return {
      hn: data.hn,
      full_name_th: data.patient_name,
      full_name: data.patient_name,
      gender: data.patient_gender,
      birthdate,
      age: diff,
      encounter_no: data.encounter,
      encounter_id: data.encounter,
      id: data.patient,
      race_name: data.race_name,
    };
  }, [mode, props.SetBedSequence?.selectedReserveBedQueue]);

  const patientData = useMemo(() => {
    const data = props.SetBedSequence?.selectedReserveBedQueue || {};
    let patientData = new PatientData(() => {});
    patientData.setPatientId(data.patient);
    patientData.setEncounterId(data.encounter);
    patientData.setEmrId(data.emr);
    patientData.setProgressionCycleId(data.progression_cycle);

    return patientData;
  }, [mode, props.SetBedSequence?.selectedReserveBedQueue]);

  const handleSelectRow = async (row: any) => {
    if (props.SetBedSequence?.selectedReserveBedQueue?.id === row?.id) {
      props.setProp(`SetBedSequence`, {
        ...props.SetBedSequence,
        selectedReserveBedQueue: null,
        selectedAdmitOrder: null,
        inputHN: "",
        isHN: false,
        selectedRoom: null,
        roomList: [],
      });

      setMode("");
    } else {
      props.setProp(`SetBedSequence`, {
        ...props.SetBedSequence,
        selectedReserveBedQueue: row,
        selectedAdmitOrder: row,
        inputHN: row?.hn,
        isHN: !!row?.hn,
      });
    }
  };

  const handleChangeValue = (e: any, v: any) => {
    const value = typeof v.checked === "boolean" ? v.checked : v.value;
    props.setProp(`SetBedSequence.filterReserveTransferQueue.${v.name}`, value);
  };

  const handleChangeDate = (key: string) => (date: string) => {
    handleChangeValue(null, {
      name: key,
      value: date,
    });
  };

  const handleChangePatient = (id: any, hn: string, full_name: string) => {
    handleChangeValue(null, {
      name: "selectedPatient",
      value: id ? { id, hn, full_name } : {},
    });
  };

  const handleSearch = () => {
    props.runSequence({
      sequence: "SetBed",
      action: "search_transfer_queue",
    });
  };

  const handleCloseModal = () => {
    setMode("");
  };

  const handleReserve = (mode: string) => {
    setMode(mode);
    props.runSequence({
      sequence: "SetBed",
      action: "search_reserve",
    });
  };

  const handleGetRoomList = (params: any) => {
    return props.onEvent({ message: "GetListRoom", params });
  };

  return (
    <div id="CardReserveTransferQueue" style={{ padding: "10px" }}>
      <CardReserveTransferQueueUX
        // data
        isHN={props.filterReserveTransferQueue.isHN}
        patientFullName={
          props.filterReserveTransferQueue.selectedPatient?.full_name
        }
        isTransferDate={props.filterReserveTransferQueue.isTransferDate}
        isTransferStatus={props.filterReserveTransferQueue.isTransferStatus}
        transferStatus={props.filterReserveTransferQueue.transferStatus}
        urgency={props.filterReserveTransferQueue.urgency}
        reserveTransferQueueList={reserveTransferQueueList}
        // options
        transferStatusOptions={props.masterOptions?.transferStatus}
        transferUrgencyOptions={props.masterOptions?.transferUrgency}
        // callback
        getTrProps={handleGetTrProps}
        onChangeValue={handleChangeValue}
        onSearch={handleSearch}
        // Element
        PatientSearchBox={
          <CardPatientSearchBox
            controller={props.drugOrderQueueController}
            onEnterPatientSearch={handleChangePatient}
            languageUX={props.languageUX}
          />
        }
        TransferStartDate={
          <DateTextBox
            value={props.filterReserveTransferQueue?.transferFromDate}
            onChange={handleChangeDate("transferFromDate")}
          />
        }
        TransferEndDate={
          <DateTextBox
            value={props.filterReserveTransferQueue?.transferToDate}
            onChange={handleChangeDate("transferToDate")}
          />
        }
        languageUX={props.languageUX}
      />

      <div style={{ display: "flex", margin: "1rem 0" }}>
        <MenuItem
          menu="transfer"
          mode={mode}
          name={intl.formatMessage({ id: "ย้าย Ward" })}
          setMode={handleReserve}
          active={!!props.SetBedSequence?.selectedReserveBedQueue?.id}
          color="teal"
        />
        <MenuItem
          menu="print"
          mode={mode}
          name={intl.formatMessage({ id: "พิมพ์เอกสาร" })}
          setMode={setMode}
          active={!!props.SetBedSequence?.selectedReserveBedQueue?.id}
          color="blue"
        />
      </div>

      <Modal open={mode === "print"} size="large">
        <CardPrintList
          printer={props.django?.user?.full_name || null}
          closeable={true}
          toggleable={true}
          onClose={handleCloseModal}
          patientInfo={patientInfo}
          encounterNumber={patientInfo.encounter_no}
          controller={props.printListController}
          patientData={patientData}
          patientId={patientData.patient_id}
          encounterId={patientData.ENCOUNTER.encounter_id}
          languageUX={props.languageUX}
        />
      </Modal>

      <CardBed
        // controller
        drugOrderQueueController={props.drugOrderQueueController}
        subICDController={props.subICDController}
        // interface
        setProp={props.setProp}
        onEvent={props.onEvent}
        // seq
        runSequence={props.runSequence}
        SetBedSequence={props.SetBedSequence}
        AdmitChangeSequence={props.AdmitChangeSequence}
        // data
        patientInfo={patientInfo}
        hideAdmitOrder={true}
        successMessage={props.successMessage}
        errorMessage={props.errorMessage}
        searchedItemList={props.searchedItemList}
        buttonLoadCheck={props.buttonLoadCheck}
        // options
        masterOptions={props.masterOptions}
        // config
        config={{
          hideRoomReserve: true,
          hideRoomAdmit: true,
          hideCancelReserve: true,
          hideCancelAdmit: true,
          hideBedMove: false,
          hideWardMove: false,
        }}
        languageUX={props.languageUX}
      />

      <Modal
        // open={openModBedMove}
        closeOnDimmerClick
        // onClose={handleCloseModBedMove}
      >
        <CardAdmitChange
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // controller
          drugOrderQueueController={props.drugOrderQueueController}
          //  seq
          runSequence={props.runSequence}
          AdmitChangeSequence={props.AdmitChangeSequence}
          // data
          buttonLoadCheck={props.buttonLoadCheck}
          searchedItemList={props.searchedItemList}
          action="CHANGE_BED"
          // selectedHN={selectedHN}
          errorMessage={props.errorMessage}
          roomNo={props.SetBedSequence?.selectedRoom?.room_no}
          // options
          masterOptions={props.masterOptions}
          // callback
          getRoomList={handleGetRoomList}
          languageUX={props.languageUX}
        />
      </Modal>
    </div>
  );
};

export default React.memo(CardReserveTransferQueue);
